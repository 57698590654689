import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../styles/portfolio.css";
import axios from "axios";

const Portfolio = () => {
  const [project, setProject] = useState([]);

  useEffect(() => {
    const getProjects = async () => {
      axios
        .get(`${process.env.REACT_APP_URL_API}/portfolio`)
        .then((res) => setProject(res.data.portfolios));
    };

    getProjects();
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1024, min: 900 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 900, min: 600 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section id="portfolio" className="portfolio">
      <h2>Proyectos</h2>
      <div className="portfolio-grid">
        {project.length && (
          <Carousel
            responsive={responsive}
            ssr
            infinite
            keyBoardControl
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {project.map((p) => (
              <div className="portfolio-item" key={p._id}>
                <img src={p.image} alt={p.title} />
                <div className="portfolio-content">
                  <h3>{p.title}</h3>
                  <p>{p.description}</p>
                  <Link to={p.url} target="_blank">
                    Ir al sitio
                  </Link>
                </div>
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </section>
  );
};

export default Portfolio;
