import React, { useEffect, useState } from "react";
import axios from "axios";
import "../styles/instagram.css";
import { Link } from "react-router-dom";

const Instagram = () => {
  const [media, setMedia] = useState([]);
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(
          "https://graph.instagram.com/me/media",
          {
            params: {
              fields:
                "id,caption,media_type,media_url,thumbnail_url,permalink,timestamp",
              access_token: process.env.REACT_APP_TOKEN_IG,
            },
          },
        );

        const filteresData = response.data.data
          .filter((item) => item.media_type !== "VIDEO")
          .slice(0, 3);
        setMedia(filteresData);
      } catch (error) {
        console.error("Error fetching user profile", error);
      }
    };

    fetchUserProfile();
  }, []);

  return (
    <div id="feed" className="instagram">
      <h2>Nuestro feed</h2>
      <div className="instagram-feed">
        {media.map((item) => (
          <Link
            to={item.permalink}
            target="_blank"
            rel="noopener noreferrer"
            key={item.id}
            className="post"
          >
            <div className="post-header">
              <img src="./assets/Logo Nettar222.jpg" alt="Profile" />
              <span className="username">nettar.web</span>
            </div>
            {item.media_type === "IMAGE" && (
              <img src={item.media_url} alt={item.caption} />
            )}
            {item.media_type === "CAROUSEL_ALBUM" && (
              <img src={item.media_url} alt={item.caption} />
            )}
            <div className="post-caption">
              {item.caption ? <p>{item.caption}</p> : null}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Instagram;
