import React from "react";
import "../styles/footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer" data-aos="fade-up">
      <div className="grupo-1">
        <div className="box-footer desktop">
          <h6>Sobre nosotros</h6>
          <p>
            Impulsando innovación a través del código. Descubre cómo podemos
            llevar tu visión al siguiente nivel
          </p>
        </div>
        <div className="box-footer">
          <figure>
            <img
              src="./assets/Logo_sin_fondo.png"
              alt="Logo Nettar"
              width="220rem"
            />
          </figure>
        </div>
        <div className="box-footer" id="contact">
          <h6>CONTÁCTENOS</h6>
          <div className="redes-sociales">
            <Link
              to="https://www.facebook.com/profile.php?id=100087493345761&locale=es_LA"
              target="_blank"
            >
              <i className="fa-brands fa-square-facebook"></i>
            </Link>
            <Link to="https://www.instagram.com/nettar.web/" target="_blank">
              <i className="fa-brands fa-square-instagram"></i>
            </Link>
            <Link
              to="https://www.linkedin.com/company/nettar-desarrollo-web/"
              target="_blank"
            >
              <i className="fa-brands fa-linkedin"></i>
            </Link>
            <Link to="mailto:nettar.web@hotmail.com" target="_blank">
              <i className="fa-solid fa-envelope"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className="grupo-2">
        <small>
          copyrigth 2024 <b>©Nettar</b> - Todos los derechos reservados.
        </small>
      </div>
    </footer>
  );
};

export default Footer;
