import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/cards.css";

const Cards = () => {
  const [service, setServices] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL_API}/service`)
      .then((res) => setServices(res.data.services));
  }, []);
  return (
    <article className="section-servicios">
      <section id="seccion-servicios">
        <div className="cards-servicios">
          <div>
            <h2 className="titulo-servicios">Tenemos mucho para ofrecerle</h2>
          </div>
          <div className="card-servicios">
            {service && service.length
              ? service.map((c, i) => (
                  <div key={i} className="box">
                    <img src={c.src} alt={c.alt} />
                    <h4>{c.title}</h4>
                    <p>{c.text}</p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>
    </article>
  );
};

export default Cards;
