import React, { useEffect, useState } from "react";
import "../styles/header.css";
import { Link } from "react-router-dom";

const Header = () => {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      if (menuActive) setMenuActive(false);
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleScroll = (event) => {
      if (menuActive) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
    };

    if (menuActive) {
      document.body.classList.add("menu-active");
      document.addEventListener("touchmove", handleScroll, {
        passive: false,
      });
      document.addEventListener("wheel", handleScroll, { passive: false });
    } else {
      document.body.classList.remove("menu-active");
      document.removeEventListener("touchmove", handleScroll);
      document.removeEventListener("wheel", handleScroll);
    }

    return () => {
      document.removeEventListener("touchmove", handleScroll);
      document.removeEventListener("wheel", handleScroll);
    };
  }, [menuActive]);

  return (
    <header>
      <nav id="navbar">
        <div className="navbar-container">
          <div className="logo" onClick={() => scrollToElement("navbar")}>
            <img src="/assets/logonettarnavbar.png" alt="Logo de Nettar" />
          </div>
          {menuActive && <ul className="background"></ul>}
          <ul className={`navbar-links ${menuActive ? "active" : ""}`}>
            <li>
              <Link onClick={() => scrollToElement("navbar")}>Inicio</Link>
            </li>
            <li>
              <Link onClick={() => scrollToElement("seccion-servicios")}>
                Servicios
              </Link>
            </li>
            {/* <li>
              <Link onClick={() => scrollToElement("seccion-nettar")}>
                Sobre Nettar
              </Link>
            </li> */}
            <li>
              <Link onClick={() => scrollToElement("feed")}>Feed</Link>
            </li>
            <li>
              <Link onClick={() => scrollToElement("portfolio")}>
                Proyectos
              </Link>
            </li>
            <li>
              <Link onClick={() => scrollToElement("contact")}>Contacto</Link>
            </li>
          </ul>
          <div className="navbar-toggle" onClick={toggleMenu}>
            <span className={`bar ${menuActive ? "active" : ""}`}></span>
            <span className={`bar ${menuActive ? "active" : ""}`}></span>
            <span className={`bar ${menuActive ? "active" : ""}`}></span>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
