import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/banner.css";
import axios from "axios";

const Banner = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL_API}/banner`)
      .then((res) => setImages(res.data.banners));
  }, []);

  return (
    <Carousel>
      {images && images.length
        ? images.map((img, i) => (
            <Carousel.Item key={i}>
              <img className="d-block w-100" src={img.src} alt={img.alt} />
            </Carousel.Item>
          ))
        : null}
    </Carousel>
  );
};

export default Banner;
