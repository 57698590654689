import React from "react";
import "../styles/form.css";
import axios from "axios";
import { useState } from "react";

const Form = () => {
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (
      !formData.name.trim() ||
      !formData.email.trim() ||
      !formData.message.trim()
    ) {
      setError("Debes completar todos los campos");
      setIsSubmitting(false);
      return;
    }

    axios
      .post(`${process.env.REACT_APP_URL_API}/messages`, {
        name: formData.name,
        email: formData.email,
        message: formData.message,
      })
      .then(() => {
        setSuccessMessage("Mensaje enviado con éxito");
        setIsSubmitted(true);
        setIsSubmitting(false);
      })
      .catch(() => {
        setError("Hubo un error, inténte más tarde");
        setIsSubmitting(false);
      });
  };

  return (
    <div className="form-container">
      <form action="" className="form-contact" onSubmit={handleSubmit}>
        <div className="container">
          <div className="title-form">
            <h4>Contacto</h4>
          </div>
          <div className="form-spaces">
            <label htmlFor="name">Nombre</label>
            <input
              type="text"
              name="name"
              id="name"
              required
              value={formData.name}
              onChange={handleChange}
              placeholder="Tu nombre"
              disabled={isSubmitting || isSubmitted}
            />
          </div>

          <div className="form-spaces">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              required
              value={formData.email}
              onChange={handleChange}
              placeholder="Tu email"
              disabled={isSubmitting || isSubmitted}
            />
          </div>

          <div className="form-spaces">
            <label htmlFor="message">Consulta</label>
            <textarea
              name="message"
              id="message"
              required
              placeholder="Tu consulta"
              value={formData.message}
              onChange={handleChange}
              disabled={isSubmitting || isSubmitted}
            ></textarea>
          </div>

          {error && <span className="alert-login">{error}</span>}
          {successMessage && (
            <span className="success-message">{successMessage}</span>
          )}

          <div className="button-container">
            <button
              className={
                !isSubmitted ? "button-contact" : "button-contact disable"
              }
              type="submit"
              disabled={isSubmitting || isSubmitted}
            >
              {isSubmitting ? "Enviando..." : "Enviar"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
